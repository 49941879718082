.overlay {
  background-color: white;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  border: 4px solid #009ed2;
  border-right-color: transparent;
  transform-origin: center;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}