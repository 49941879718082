@import '~antd/dist/antd.css';

@import '~react-quill/dist/quill.snow.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-absolute {
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-mark {
  background-color: rgba(255, 255, 255, 0.8);
}

.ant-popover {
  z-index:1055
}

.left-16 {
  margin-left: 16px;
}
.padding-right-8{
  padding-right: 8px;
}
.margin-top-8{
  margin-top: 8px;
}

.App-flex-row{
  display: flex;
  flex-direction: row;
}
.App-flex-col{
  display: flex;
  flex-direction: column;
}
.width-100{
  width: 100%;
}

.App-modal-loading{
  display: flex;
  justify-content: center;
  align-items: center;
}